<template>
  <div class="page">
    <img src="../../assets/login/login_back.png" class="naviga_logo">
    <div class="content">
      <div class="item">
        <img src="../../assets/phone_icon.png" class="phone_icon">
        <mu-text-field
          :label="$t('phone')"
          prefix="+52"
          v-model="form.phone"
          label-float
          full-width
          color="#A194B4"
          type="tel"
          v-mask="'#### #### ##'"
        />
      </div>
      <div class="buttons">
        <mu-button full-width color="#E7AD5C" textColor="#18252A" @click="onLogin">{{ $t("login") }}</mu-button>
        <mu-button full-width color="#18252A" textColor="#E7AD5C" style="border: 2px solid #E7AD5C;"  @click="onSignUp">{{ $t("register") }}</mu-button>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import { mapState, mapMutations } from "vuex";
export default {
  name: 'User',
  mixins: [mixin],
  data() {
    return {
      form: {
        phone: '',
      }
    }
  },
  computed: {
    ...mapState(["record"]),
  },
  mounted() {
    this.form.phone = this.record.loginPhone || "";
  },
  methods: {
    ...mapMutations(["SET_RECORD_ITEM"]),
    onLogin() {
      this.SET_RECORD_ITEM({
        key: "loginPhone",
        value: this.form.phone,
      });
      this.$router.push('/user/login')
    },
    onSignUp() {
      this.SET_RECORD_ITEM({
        key: "registerPhone",
        value: this.form.phone,
      });
      this.$router.push('/user/register')
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
  background: #2A1B3F;
  /deep/ .mu-input-prefix-text {
    color: #F4F4F4;
  }
  .naviga_logo {
    position: absolute;
    top: 0;
    width: 100%;
    display: block;
  }
  .naviga_amount {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    width: 100%;
  }
  .content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #18252A;
    .item {
      display: flex;
      margin: 0px 20px 0px 20px;
      color: #F4F4F4;
      .phone_icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-left: 5px;
        margin-top: 35px;
      }
    }
    .buttons {
      margin-left: 20px;
      margin-right: 20px;
    }
    /deep/ .mu-text-field-input {
      color: #F4F4F4;
      height: 38px;
    }
    /deep/ .mu-input-label {
      color: #F4F4F4;
    }
    /deep/ .mu-input-line {
      background-color: #F4F4F4;
    }
    /deep/ .mu-raised-button {
      border-radius: 8px;
      height: 50px;
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }
}
</style>
